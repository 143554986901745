import Vue from "vue"
import App from "./App.vue"
import util from "@/libs/util.js"
import { getEvnConfig } from "@/utils/appConfig"
import store from "@/store/index"
import ElementUI from "element-ui"
import VueAMap from 'vue-amap';
import "element-ui/lib/theme-chalk/index.css"
Vue.use(ElementUI)
Vue.config.ignoredElements=['wx-open-launch-weapp']
// 菜单和路由设置
import router from "./router"
Vue.config.devtools = true
import VueRouter from "vue-router"
import storage from "@/utils/storage";
// //内部跳转
// Vue.prototype.JumpInside = function (type, id, state) {
//   let routeUrl
//   if (type == 6) {
//     //联系我们
//     routeUrl = this.$router.resolve({
//       path: "/contact",
//     })
//   }
//   window.open(routeUrl.href, "_blank")
// }
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'your amap key',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});
Vue.config.productionTip = false
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});
Vue.use(VueRouter)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
