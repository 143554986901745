import Vue from "vue";
import VueRouter from "vue-router";
import errorf from '@/views/error/404/index.vue'
import storage from "@/utils/storage";
import Fingerprint2 from "fingerprintjs2";

// 关于我们
const aboutUs = [
    {
        path: '/aboutUs/index',
        name: 'aboutUs',
        component: () => import('@/views/aboutUs/index.vue')
    },
]

const routes = [
    {
        path: '/',
        redirect: 'index'
    },
    {
        path: '/index',//首页
        name: 'home',
        component: () => import('@/views/home/index.vue')
    },
    {
        path: '/contact',//联系我们
        name: 'contact',
        component: () => import('@/views/contact/index.vue')
    },
    {
        path: '/joinPage',//加盟页面
        name: 'joinPage',
        component: () => import('@/views/joinPage/index.vue')
    },
    {
        path: '/design',//UI设计
        name: 'design',
        component: () => import('@/views/customized/design.vue')
    },
    {
        path: '/other',//咨询定制
        name: 'other',
        component: () => import('@/views/customized/other.vue')
    },
    {
        path: '/liandongyun',//链动云
        name: 'liandongyun',
        component: () => import('@/views/products/liandongyun.vue')
    },
    {
        path: '/lianyunpiao',//链云票
        name: 'lianyunpiao',
        component: () => import('@/views/products/lianyunpiao.vue')
    },
    {
        path: '/lianjiebao',//链捷报
        name: 'lianjiebao',
        component: () => import('@/views/products/lianjiebao.vue')
    },
    {
        path: '/qianhuiduo',//钱会多
        name: 'qianhuiduo',
        component: () => import('@/views/products/qianhuiduo.vue')
    },
    {
        path: '/ruziniu',//孺子牛
        name: 'ruziniu',
        component: () => import('@/views/products/ruziniu.vue')
    },
    {
        path: '*',
        name: 'error',
        component: errorf
    },
    ...aboutUs,

];


// fix vue-router NavigationDuplicated
Vue.use(VueRouter);

// 导出路由 在 main.js 里使用
const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    if(!storage.getStorage("fingerprint")){
        Fingerprint2.get(components => {
            // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
            const values = components.map(component => component.value); // 配置的值的数组
            const murmur = Fingerprint2.x64hash128(values.join(""), 31); // 生成浏览器指纹
            storage.setStorage("fingerprint", murmur);
        });
        if(to.path =="//" ){
            setTimeout( next({ name: 'home' }), 300);
        }else {
            setTimeout( next(), 300);
        }
    }else{
        if(to.path =="//" ){
            next({ name: 'home' })
        }else {
            next()
        }
    }
});
export default router;
