<template>
  <div id="app">
    <router-view  style="min-width: 1200px;"></router-view>
    <el-backtop :bottom="80"></el-backtop>
  </div>
</template>
<script>
export default {
  name: 'App',
  components: {

  }
}
</script>
<style lang="scss">

* {
 margin: 0;
 padding: 0;
 text-decoration: none;
 font-family: PingFang SC;
 list-style: none;
}
.el-backtop{
  background-color:#409EFF!important;
  color: #fff!important;
}
</style>


